<template>
  <div class="submit_succ">
    <span>支付成功</span>
    <span>搜图大师傅官网</span>
  </div>
</template>

<script>
import { get_token } from "../common/common.js";
export default {
  data() {
    return {
      params: {},
    };
  },

  methods: {
    async send_request_er() {
      const token = get_token("token");

      const verify_res = await this.$http({
        method: "post",
        url: "http://www.soutudashi.com/pay_success", // 拼接URL和参数
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*", // 设置跨域参数
          "Access-Control-Allow-Methods": "GET, POST, PUT, DELETE, OPTIONS",
          Authorization: `Bearer ${token}`,
        },
        data: this.params,
        responseType: "json",
      });
    },
  },
  mounted() {
    this.params = this.$route.query;
    console.log("查询参数:", this.params);
    this.send_request_er();

    setTimeout(() => {
      // 假设你要跳转到 '/home' 路径
      this.$router.go(-1).catch((err) => {
        // 处理可能出现的错误，比如路由已经被守卫拦截
        console.error(err);
      });
    }, 1000000000);
  },
};
</script>

<style scoped>
.submit_succ {
  width: 1120px;
  min-height: 800px;
  background-color: white;
  margin: 20px auto;
}

.submit_succ span {
  width: 200px;
  height: 70px;
  color: black;
  font-size: 20px;
}
</style>
